@use '../public/assets/scss/constants/colors' as colors;

// ===============================================
// Estilos para fontawesome
// ===============================================
@import url("https://pro.fontawesome.com/releases/v6.0.0-beta1/css/all.css");

// ===============================================
// Estilos generales
// ===============================================
body {
  margin: 0;
  background-color: colors.$bg-element;
  font-family: "GeistSans", "GeistSans Fallback", system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

code,
.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}