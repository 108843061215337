@use '../../../../../public/assets/scss/constants/colors' as colors;
@use '../../../../../public/assets/scss/components/animations' as animations;

// Elementos del sidebar => Elements.jsx
.sidebar-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;

    .element {
        position: relative;
        display: flex;
        flex-direction: row;
        text-decoration: none;
        margin-top: 10px;
        gap: 5px;
        width: 200px;
        transition: all 0.3s;

        .icon {
            position: relative;
            min-width: 30px;
            padding: 5px;
            font-size: 1.4em;
            border-radius: 15px;
            color: var(--white);
            background-color: var(--white-10);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            overflow: hidden;
            z-index: 1;

            /* Luz solo en el borde */
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 15px;
                border: 1px solid transparent;
                box-sizing: border-box;
                background: linear-gradient(90deg,
                        rgba(colors.$primary, 0) 0%,
                        rgba(colors.$primary, 0) 25%,
                        rgba(colors.$primary, 1) 50%,
                        rgba(colors.$primary, 0) 75%,
                        rgba(colors.$primary, 0) 100%);
                z-index: 1;
                mask: linear-gradient(var(--white) 0 0) content-box, linear-gradient(var(--white) 0 0);
                animation: lightGlow 5s infinite linear;
                background-size: 200% 200%;
                mask-composite: exclude;
                pointer-events: none;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            min-width: 150px;
            text-align: left;
            font-size: 0.8em;
            margin: 0px 10px;
            color: var(--white);
            gap: 3px;
            justify-content: center;


            .label {
                font-weight: bold;
                color: var(--gray);
            }

            .name {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .selector {
        cursor: pointer;

        &:hover {
            border-radius: 15px;
            padding: 5px;
            background-color: colors.$bg-content;
        }
    }
}