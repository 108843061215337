@use '../../../../../public/assets/scss/constants/colors' as colors;

.overflow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--black-50);
    z-index: 99999;
}

.alert-successxx {
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 400px;
    min-height: 200px;
    top: 50%;
    left: 50%;
    padding: 10px;
    justify-content: center;
    color: colors.$text-primary;
    background-color: colors.$bg-content;
    border-radius: 15px;
    border: 1px dashed var(--white);
    box-shadow: 2px 2px 10px var(--black);
    transform: translate(-50%, -50%);
    overflow: hidden;
    z-index: 100000;

    &::before {
        content: '';
        position: absolute;
        height: 600px;
        width: 600px;
        left: -25%;
        top: -100%;
        background-image: conic-gradient(lighten(colors.$primary, 10) 20deg, transparent 120deg);
        animation: rotate-light 3s linear infinite;
        z-index: -1;
    }

    &::after {
        content: "";
        position: absolute;
        width: calc(100% - 65px);
        height: calc(100% - 15px);
        left: 50%;
        bottom: 50%;
        padding: 5px 30px;
        border-radius: 15px;
        transform: translate(-50%, 50%);
        background-color: colors.$bg-content;
        z-index: -1;
    }

    .close {
        position: absolute;
        top: 15px;
        right: 15px ;
        padding: 5px 8px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        color: colors.$text-primary;
        background-color: transparent;
        transition: all 1s;

        &:hover {
            background-color: lighten(colors.$bg-content, 10);
        }
    }

    .icon {
        font-size: 3em;
    }

    .title {
        padding: 0;
        margin: 0;
    }

    .messaje {
        padding: 0 20px;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center !important;

        .button1 {
            height: 30px;
        }
    }
}