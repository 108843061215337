@use '../../../../public/assets/scss/constants/colors' as colors;

.tooltip {
  position: absolute;
  white-space: wrap;
  max-width: 200px;
  font-size: 12px;
  padding: 10px 15px;
  border-radius: 5px;
  color: var(--white);
  border: 1px dashed var(--white-20);
  background-color: lighten(colors.$bg-content, 10%);
  opacity: 1;
  z-index: 9999;
  transition: opacity 0.2s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    border-style: solid;
  }

  &.tooltip-top::before {
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px 8px 0 8px;
    border-color: lighten(colors.$bg-content, 10%) transparent transparent transparent;
  }

  &.tooltip-right::before {
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 8px 8px 8px 0;
    border-color: transparent lighten(colors.$bg-content, 10%) transparent transparent;
  }

  &.tooltip-bottom::before {
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent lighten(colors.$bg-content, 10%) transparent;
  }

  &.tooltip-left::before {
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent lighten(colors.$bg-content, 10%);
  }
}

.invisible {
  display: none;
}

.tooltip-container {
  display: inline-block;
  cursor: pointer;
}