// Elementos de redes sociales => Social.jsx
.container_social {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding-bottom: 20px;

    .social-links {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 20px;
        padding: 10px;
        justify-content: center;

        .social-link {
            text-decoration: none;
            color: var(--white);
            font-size: 1.3em;
        }
    }
}