:root {
    --black: hsl(0, 0%, 0%);
    --black-90: hsla(0, 0%, 0%, 0.9);
    --black-80: hsla(0, 0%, 0%, 0.8);
    --black-70: hsla(0, 0%, 0%, 0.7);
    --black-60: hsla(0, 0%, 0%, 0.6);
    --black-50: hsla(0, 0%, 0%, 0.5);
    --black-40: hsla(0, 0%, 0%, 0.4);
    --black-30: hsla(0, 0%, 0%, 0.3);
    --black-20: hsla(0, 0%, 0%, 0.2);
    --black-10: hsla(0, 0%, 0%, 0.1);

    --white: hsl(0, 0%, 100%);
    --white-90: hsl(0, 0%, 100%, 0.9);
    --white-80: hsl(0, 0%, 100%, 0.8);
    --white-70: hsl(0, 0%, 100%, 0.7);
    --white-60: hsl(0, 0%, 100%, 0.6);
    --white-50: hsl(0, 0%, 100%, 0.5);
    --white-40: hsl(0, 0%, 100%, 0.4);
    --white-30: hsl(0, 0%, 100%, 0.3);
    --white-20: hsl(0, 0%, 100%, 0.2);
    --white-10: hsl(0, 0%, 100%, 0.1);

    --gray: hsl(0, 0%, 50%);
}