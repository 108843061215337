@use '../../../assets/scss/constants/colors' as colors;

.button {
    display: inline-block;
    width: 100%;
    max-width: 200px;
    margin: 10px 0;
    padding: 0px 10px;
    font-size: 0.8em;
    outline: none;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    border-radius: 50px;
    border: 1px solid colors.$primary;
    color: colors.$text-primary;
    background-color: darken(colors.$primary, 10);
    box-shadow: 0 2px 5px var(--black-20);
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        background-color: darken(colors.$primary, 20%);
    }

    &:active {
        background-color: darken(colors.$primary, 20%);
    }
}

.button1 {
    height: 40px;
    padding: 0px 20px;
    color: colors.$text-primary;
    border-radius: 15px;
    cursor: pointer;
    border: 1px dashed var(--white-50);
    background-color: var(--white-10);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    transition: all 0.3s;
    
    &:hover {
        border: 1px dashed colors.$primary;
    }
}