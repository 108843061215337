@use '../../../../public/assets/scss/constants/colors' as colors;

.navbar {
    position: fixed;
    display: flex;
    right: 0;
    gap: 5px;
    margin-top: 10px;
    margin-right: 10px;
    padding: 5px 6px;
    font-size: 1em;
    border-radius: 15px;
    text-decoration: none;
    color: colors.$text-primary;
    background-color: var(--white-10);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 4px 30px var(--black-10);
    transition: all 0.3s;
    z-index: 1000;

    @media (max-width: 1024px) {
        position: fixed;
        width: 100%;
        flex-wrap: wrap;
        bottom: 0;
        right: 0;
        left: 0;
        gap: 0;
        padding: 10px 0;
        justify-content: center;
        border-radius: 15px 15px 0 0;
        z-index: 1000;
    }

    a {
        width: 74px;
        padding: 10px;
        border-radius: 15px;
        text-decoration: none;
        text-align: center;
        color: colors.$text-primary;
        border: 1px solid transparent;
        transition: all 0.3s;

        &:hover {
            font-weight: bold;
        }

        &.active {
            font-weight: bold;
            color: lighten(colors.$primary, 25);
            background-color: colors.$bg-element;
            border: 1px dashed rgba(colors.$bg-secondary, 0.4);
            text-shadow: 0 0 75px rgba(colors.$primary, 0.5), 0 0 20px rgba(colors.$primary, 0.6);
        }

        @media (max-width: 485px) {
            width: 60px;
            font-size: 0.8em;
        }
    }

}