@use '../../../../public/assets/scss/constants/colors' as colors;
@use '../../../../public/assets/scss/components/scroll';

.sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: colors.$bg;
    color: var(--white);
    border-right: 1px solid rgba(colors.$bg-secondary, 0.2);
    z-index: 1000;
    transition: width 0.3s ease-in-out;
    user-select: none;

    @media (max-width: 1024px) {
        .container_social {
            margin-bottom: 40px;
        }
    }

    .container_logo {
        transition: all 0.3s ease-in-out;

        .logo {
            width: 50px;
            height: 50px;
            margin: 0;
            border-radius: 30px;
            transition: all 0.3s ease-in-out;
        }
    }

    /* Sidebar retraído */
    &.retracted {
        width: 50px;

        @media (max-width: 768px) {
            display: grid;
            height: 50px !important;
            border: 1px solid rgba(colors.$bg-secondary, 0.2) !important;
            border-radius: 15px;
            margin-top: 10px;
            margin-left: 10px;

            .container_logo {
                .logo {
                    width: 50px;
                    height: 50px;
                    visibility: hidden;
                }

                .toggle-btn {
                    opacity: 1 !important;
                    border: none !important;

                    &:hover {
                        border: none !important;
                    }
                }
            }

            .divisor {
                visibility: hidden;
            }

            .sidebar-content {
                display: none;
            }

            .container_social {
                display: none;
            }
        }

        .container_logo {
            padding-top: 0px;

            .ocupation {
                visibility: hidden !important;
                opacity: 0 !important;
                transform: scale(0) !important;
                transition: all 0.3s ease-in-out;
                transition: visibility 0s, opacity 0s, transform 0s;
            }

            .toggle-btn {
                opacity: 0;
                border: 1px solid rgba(colors.$bg-secondary, 0.2);
            }
        }

        .sidebar-content {
            gap: 0px;

            .element {
                width: auto;

                .text {
                    display: none;
                }
            }
        }

        .container_social {
            .social-links {
                flex-direction: column;
                gap: 5px;
                transition: all 0.3s;
            }
        }
    }

    /* Sidebar expandido */
    &.expanded {
        width: 250px;

        @media (max-width: 768px) {
            height: 100% !important;
        }

        .container_logo {
            position: relative;
            padding-top: 0px;

            .logo {
                width: 210px;
                height: 210px;
                margin: 20px;
                border-radius: 30px;
                background-color: var(--white-10);
                backdrop-filter: blur(10px);
                -webkit-backdrop-filter: blur(10px);
            }

            .ocupation {
                position: absolute;
                overflow: hidden;
                width: 100px;
                bottom: 10px;
                left: 50%;
                letter-spacing: 1.7px;
                padding: 5px 30px;
                border-radius: 50px;
                opacity: 1;
                visibility: visible;
                background-color: colors.$bg-element;
                transform: translate(-50%, 0) scale(1);
                border: 1px dashed rgba(colors.$bg-secondary, 0.2);
                transition: opacity 0s ease, visibility 0s ease, all 1s ease;

                &::before {
                    content: '';
                    position: absolute;
                    height: 1200%;
                    width: 100%;
                    left: 0;
                    top: -550%;
                    background-image: conic-gradient(colors.$primary 20deg, transparent 120deg);
                    animation: rotate-light 3s linear infinite;
                    z-index: -1;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 96px;
                    height: 18px;
                    left: 50%;
                    bottom: 2px;
                    padding: 5px 30px;
                    border-radius: 15px;
                    transform: translate(-50%, 0);
                    background-color: colors.$bg-element;
                    z-index: -1;
                }
            }

            .toggle-btn {
                right: 0;
                transition: all 0.3s ease-in-out;
                border: 1px dashed rgba(colors.$bg-secondary, 0.2);
            }
        }
    }

    // Contenedor de imagen y botón de cambio
    .container_logo {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 10px;

        .ocupation {
            position: absolute;
            opacity: 0;
            transition: all 0.3s;
        }

        .toggle-btn {
            position: absolute;
            display: grid;
            width: 40px;
            height: 40px;
            padding: 10px;
            margin: 5px;
            font-size: 18px;
            text-align: center;
            align-content: center;
            border: none;
            border-radius: 10px;
            color: var(--white);
            background-color: colors.$bg;
            cursor: pointer;
            outline: none;
            transition: all 0.3s;

            &:hover {
                opacity: 1;
                background-color: var(--white-10);
                backdrop-filter: blur(10px);
                -webkit-backdrop-filter: blur(10px);
            }

            @media (max-width: 768px) {
                font-size: 1.5em;
            }
        }
    }

    .divisor {
        width: 85%;
        border: none;
        border-top: 1px dashed rgba(colors.$bg-secondary, 0.2);
        margin: 0px auto;

    }
}