@use 'root_colors';

$primary: #8123b8;
$secondary: #121212;

$bg: #000;
$bg-element: #09090b;
$bg-content: #121212;

$text-primary: var(--white);
$text-secondary: var(--white-80);


$bg-secondary: #ecf0f3;

$text-shadow: #494949;