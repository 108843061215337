// ===============================================
// SVG
// ===============================================

/* Keyframes para el efecto de pulso */
@keyframes pulseAnimation {
    0% {
        stroke-dashoffset: 100;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

/* Keyframes para mover el haz de luz */
@keyframes lightTrailAnimation {
    0% {
        stroke-dashoffset: 300;
        opacity: 0;
    }

    80% {
        stroke-dashoffset: 0;
        opacity: 1;
    }

    100% {
        stroke-dashoffset: 0;
        opacity: 0;
    }
}

// ===============================================
// Contenedores
// ===============================================

/* Animación del haz de luz bordeando solo el contorno */
@keyframes lightGlow {
    0% {
        background-position: 0 0;
    }

    25% {
        background-position: 50% 0;
    }

    50% {
        background-position: 100% 0;
    }

    75% {
        background-position: 150% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

/* Animacion donde el haz de luz gira al rededor de un elemento */
@keyframes rotate-light {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

/* Animacion para mostrar las cards */
@keyframes scaleUp {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}

/* Animacion para mostrar la texto */
@keyframes translateLeft {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}