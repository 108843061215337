@use '../constants/colors' as colors;

.input {
    all: unset;
    height: 10px;
    padding: 20px;
    text-align: left;
    border-radius: 15px;
    color: colors.$text-primary;
    border: 1px solid rgba(colors.$bg-secondary, 0.5);

    &:focus {
        border: 1px solid colors.$primary;
    }

    &:-webkit-autofill {
        -webkit-text-fill-color: var(--white) !important;
        -webkit-box-shadow: 0 0 0px 1000px var(--white-10) inset;
        transition: background-color 5000s ease-in-out 0s;

    }
}

.textarea {
    min-height: 40px;
    max-height: 80px;
    resize: vertical;

    &::-webkit-scrollbar-track {
        background: transparent !important;
    }
}