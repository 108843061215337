@use '../public/assets/scss/constants/colors' as colors;
@use '../public/assets/scss/components/buttons';
@use '../public/assets/scss/components/input';

.App {
  position: relative;
  display: flex;
  height: 100vh;
  text-align: center;
  color: colors.$text-primary;
}

.main-content {
  flex: 1;
  width: 100%;
  min-width: 450px;
  transition: margin-left 0.3s ease;

  /* Sidebar retraído */
  &.retracted {
    margin-left: 50px;
  }

  /* Sidebar expandido */
  &.expanded {
    margin-left: 250px;
  }

  /* Cuando el sidebar no está visible, el contenido ocupa todo el espacio */
  &.no-sidebar {
    margin-left: 0;
  }

  .title-page {
    position: relative;
    margin-left: 50px;
    text-align: left;
    z-index: -1;

    @media (max-width: 768px) {
      margin-left: 75px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 40px;
      height: 5px;
      bottom: -5px;
      left: 0;
      background: colors.$primary;
      border-radius: 3px;
    }
  }

  @media (max-width: 768px) {
    margin-left: 0 !important;
  }
}
