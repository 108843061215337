@use "../constants/colors" as colors;

/*-----------------------------------*\
  # Scroll PAGE
\*-----------------------------------*/

body {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(colors.$primary, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid darken(colors.$primary, 20);
    background: rgba(colors.$primary, 0.1);
    border-radius: 20px;
    box-shadow:
      inset 1px 1px 0 rgba(colors.$primary, 0.9),
      inset -1px -1px 0 rgba(colors.$primary, 0.9);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(colors.$primary, 0.9);
  }

  &::-webkit-scrollbar-button {
    height: 0px;
  }
}

/*-----------------------------------*\
  # Scroll elements
\*-----------------------------------*/

@mixin scroll_element {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: lighten(colors.$bg-element, 10);
  }

  &::-webkit-scrollbar-thumb {
    background: lighten(colors.$bg-element, 50);
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      background: rgba(colors.$primary, 0.8);
    }
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
}

// Scrool para elementos
.scroll_element {
  @include scroll_element;
}

// Scrool para sidebar
.scroll_element_sidebar {
  @include scroll_element;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-button {
    height: 20px;
  }
}